<template>
  <!-- eslint-disable vue/no-v-html -->
  <signup-fail-template data-test="layout">
    <template #page_title>
      <div data-test="title" v-html="$t(`${langPath}.title`)" />
    </template>

    <template #content>
      <message
        data-test="warning_message"
        type="warning"
        :text="warningText"
        icon-name="exclamation-triangle"
      />
      <p data-test="description_line1">
        <strong>
          {{ $t(`${langPath}.line1`) }}
        </strong>
      </p>
      <p data-test="description_line2">
        {{ $t(`${langPath}.line2`) }}
      </p>
      <p data-test="description_line3">
        {{ $t(`${langPath}.line3`) }}
      </p>

      <section class="signup-fail-template__contact-info">
        <p>
          <ev-icon color="blue" name="envelope" />
          <span class="signup-fail-template__contact-info-email">
            <a :href="linkToContactEmail">{{ contactEmail }}</a>
          </span>
        </p>

        <p>
          <ev-icon color="blue" name="phone" />
          {{ $t(`${langPath}.phone`) }}
        </p>
      </section>

      <ev-link
        :a-href="scheduleUrl"
        rel="noopener noreferrer"
        target="_blank"
        data-test="contact-link"
      >
        {{ $t(`${langPath}.contactButton`) }}
      </ev-link>
    </template>
  </signup-fail-template>
</template>

<script>
import SignupFailTemplate from './components/SignupFailTemplate'
import EvIcon from '@revelotech/everestV2/EvIcon'
import EvLink from '@revelotech/everestV2/EvLink'
import { sdrScheduleUrl } from '@/helpers/constants/external-urls'
import gtmEvents from '@/helpers/constants/gtm-events'
import Message from './Message'
import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'

const authHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'InactiveCompany',
  components: {
    EvIcon,
    EvLink,
    Message,
    SignupFailTemplate
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...authHelper.mapState(['currentUser']),
    linkToContactEmail () {
      return `mailto:${this.contactEmail}`
    },
    contactEmail () {
      return this.$t(`${this.langPath}.email`)
    },
    scheduleUrl () {
      return sdrScheduleUrl
    },
    warningText () {
      return this.$t(
        `${this.langPath}.warning`, {
          emailDomain:
            this.currentUser?.email?.split('@')?.pop()
        }
      )
    }
  },
  async mounted () {
    await this.getCurrentUser()
    this.$gtmTrackEvent(gtmEvents.companySignupRecurrence, {
      uidValue: this.currentUser.id
    })
  },
  methods: {
    ...authHelper.mapActions(['getCurrentUser'])
  }
}
</script>

<style lang="scss">
  .signup-fail-template {
    &__contact-info {
      p {
        margin-bottom: $base * 5;

        i {
          padding-right: $base * 2;
        }
      }
    }

    &__contact-info-email {
      color: var(--tx-blue);

      a {
        text-decoration: underline;
      }
    }
  }
</style>
